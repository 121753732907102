
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, onMounted } from '@cloudfun/core'
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import "@/global-components/ckeditor/styles.css";

export default defineComponent({
  components: {
  },
  setup () {
    const model = CloudFun.current?.model;
    const data = ref<any>({
      Id: 0,
      Type: 10,
      Name: "field",
      ModelType: 1,
      ViewCount: 0,
      Content: "",
    });

    function schemaCustomization(editor: any) {
      editor.model.schema.extend("$block", {
        allowAttributes: "classList"
      });
      editor.conversion.attributeToAttribute({
        model: {
          key: "classList"
        },
        view: {
          key: "class"
        }
      });
    }

    const classicEditor = ClassicEditor;
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "alignment",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
          "sourceEditing"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    };

    const save = () => {
      if(data.value.Id !== 0) {
        model?.dispatch("template/update", data.value).then(()=> {
          CloudFun.send("info", {
            subject: "執行完成",
            content: "儲存成功",
          });
        });
      } else {
        model?.dispatch("template/insert", data.value).then((req)=> {
          console.log(req)
          data.value = req;
          CloudFun.send("info", {
            subject: "執行完成",
            content: "儲存成功",
          });
        });
      }
      
    };

    const loadData = () => {
      const condition = new Condition();
      condition.and("Type", Operator.Equal, 10);
      condition.and("ModelType", Operator.Equal, 1);
      condition.and("Name", Operator.Equal, "field");

      return model?.dispatch("template/query", {
        condition,
        keyword: null,
      });
    };

    onMounted(async() => {
      try {
        const templateData = await loadData();
        console.log('temp', templateData);
        if(templateData && templateData.length) data.value = templateData[0];
      } catch (error: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: error,
        });
      }
    });

    return {
      data,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
      classicEditor,
      editorConfig,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      save,
    }
  },
  methods: {
    onGridRemoveSelectedRows (rows: any[], callback: any) {
      cash('#batch-dropdown').dropdown('hide')
      callback()
    },
  }
})
